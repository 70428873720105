.header{
  background-color: black;
  font-family: Helvetica, arial, sans-serif;
  color: rgb(255, 75, 18);
  position: sticky;
  top: 0;
  z-index: 2000;
  width: 100%;
}

.divider{
  background-color:rgb(255, 75, 18);
  height: .4rem;
  width: 100%;
}
.header-logo{
  transition: max-height 0.5s ease 0s; 
  will-change: transform; 
  max-height: 80px; 
  width: auto;
}

.smaller{
  transition: max-height 0.5s ease 0s; 
  will-change: transform; 
  max-height: 64px; 
  width: auto;
}

.navbar-elements{

  display: flex;
  flex-direction: row;
  padding-top: 1%;
  padding-bottom: 1%;
  flex-wrap: nowrap;
  margin-left: auto;
  margin-right: auto;
}

.header-logo-container{
  padding-left: 3%;
  max-width: 4%;
  display: flex;
  flex-basis: auto;
  flex-grow: 1;
  justify-content: flex-start;
  margin-right: 1%;
}

.header-logo-container:hover{
  cursor: pointer;
}

.header-logo-container.rtl{
  max-width: 6%;
  display: flex;
  flex-basis: auto;
  flex-grow: 1;
  justify-content: flex-start;
  /* margin-right: -3%; */
  /* margin-left: 3%; */
  padding-right: 3%;
  padding-left: 0%;
}

.header-links-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
 
}
.header-links-container ul li{
  padding-left: 28px;
  display: inline-flex;
  list-style: none;
  font-size: 16px;
  white-space: nowrap;
}

.header-links-container ul.rtl li{
  font-size: 18px;
}

.header-links-container ul.rtl{
  direction:rtl;
}


.header-links-container ul li a{
  color: white;
  text-decoration: none;
  font-weight: 400;
  text-transform: uppercase;
  padding-bottom: 5%;
  padding-top: 10%;
}

.header-links-container ul li a:hover{
  color: rgb(255, 75, 18);
  
  transition: all 0.5s ease 0s;
  cursor: pointer;
}

.header-links-container ul ul{
  position: absolute;
  top: 168px;
  /* margin-left: 10%; */
  opacity: 0;
  visibility: hidden;
  
  background: black;
  margin: 0px;
  padding: 0px;
  z-index: 2000;
}

.header-links-container ul ul.banner-hidden{
  top: 84px;
}

.header-links-container ul ul li{

  position: relative;
  margin: 0px;
  left: -10%;
  background: black;
  width: 100%;
  display: inline-table;
  padding: 0px;
  display: list-item;
  color: red;
  padding-right: 50%;
  z-index: 2000;
}

.header-links-container ul.rtl ul li{
  position: relative;
  margin: 0px;
  left: 10%;
  background: black;
  text-align: right;
  width: 100%;
  padding: 0px;
  display: inline-table;
  display: list-item;
  color: red;
 
  padding-left: 50%;
  padding-right: 10%;
}
.header-links-container > ul > li > ul > li:nth-child(1){
  border-top: 3px solid red;
  
}
.header-links-container ul ul li a{
  
  line-height: 50px;
  padding-left: 10%;
  padding-top: 10%;
  padding-bottom: 10%;
  
  width: 100%;
}

.header-links-container ul.rtl ul li a{
  line-height: 50px;
  padding-left: 10%;
  padding-top: 10%;
  padding-bottom: 10%;
  
  width: 100%;

}

.header-links-container ul li:hover > ul{

  opacity: 1;
  visibility: visible;
}

.header-links-container ul.rtl li:hover > ul{
  opacity: 1;
  visibility: visible;
}

.header-links-container ul ul ul{
  position: absolute;
  top: 0;
  left: 100%;
  opacity: 0;
  visibility: hidden;
  
  background: black;
  margin: 0px;
  padding: 0px;
  z-index: 2000;
}

.header-links-container ul.rtl ul ul{
  background: black;
  position: absolute;
  top: 0;
  left: -100%;
  right: 100%;

  opacity: 0;
  visibility: hidden;
  
  margin: 0px;
  padding: 0px;
  z-index: 2000;
}

.header-links-container ul ul ul li{
  position: relative;
  left: 5%;
  /* top: -168px; */
  /* top: -335%; */
  /* left: 260px; */
  /* left: 565%; */
  padding-right: 20%;
}

.header-links-container ul.rtl ul ul li{
  position: relative;
  left: -5%;
  /* top: -168px; */
  /* top: -335%; */
  /* left: 260px; */
  /* left: 565%; */
  padding-left: 10%;
  padding-right: 0%;

}



.header-links-container ul ul ul li a{
  padding-left: 0%;
  /* top: -168px; */
  /* top: -335%; */
  /* left: 260px; */
  /* left: 565%; */
}



.header-links-container ul li a.selected{
  color: rgb(255, 75, 18);
  font-weight: 700
}


.child-indicator{
  position: absolute;
  bottom: 0;
  right: 2%;
  /* top: 20%; */
  top: 25%;
}

.child-indicator.rtl{
  transform: rotate(180deg);
  position: absolute;
  bottom: 20%;
  /* right: 85%; */
  right: 90%;
  top: 0%;
}
.language-switch{
  display: flex;
  padding-top: 15px;
  padding-left: 2%;
}

.language-switch.rtl{
  padding-left: 0%;
}

.search-input{
  display: inline;
  min-width: 550px;
}

.row-rtl{
  flex-direction: row-reverse;
}


@media only screen and (min-width: 1600px) and (max-width: 1820px){


  .header-links-container ul li{
    padding-left: 18px;
    display: inline-flex;
    list-style: none;
    font-size: 14px;
    
  }

  .header-links-container ul.rtl li{
    font-size: 16px;
  }
  
} 


@media only screen and (min-width: 1366px) and (max-width: 1599px){
  .header-links-container ul li{
    padding-left: 18px;
    display: inline-flex;
    list-style: none;
    font-size: 12px;
    
  }

  .header-links-container ul.rtl li{
    font-size: 13px;
  }
  

  .switch-label{
    font-size: 11px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px){
  .header-links-container ul li{
    padding-left: 18px;
    display: inline-flex;
    list-style: none;
    font-size: 12px;
    
  }

  
  

  .header-links-container ul.rtl li{
    font-size: 13px;
  }
  

  .switch-label{
    font-size: 10px;
  }
}

.burger-button-mobile{
  display: none;
}

.search-button-mobile{
  display:none;
}
.search-input-mobile-container{
  display: none;
}
.search-input-mobile{ 
  display: none;
}
.header-button-row-mobile{
  display: none;
}

.shopping-cart-button-mobile{
  display: none;
}
@media only screen and (min-width: 0px) and (max-width: 800px){
  .header-links-container{
    display: none;
  }

  .language-switch{
    display: none;
  }

  .header{
  
    display: block;
  }

  .header-logo-container{
    max-width: 25%;
  }
  

  .header-logo-container.rtl{
    flex-direction: row-reverse;
    max-width: 25%;
  }
.burger-button-mobile{
  display: inline-flex;
  align-self: center;
  /* padding-right: 3%; */
  margin-right: 3%;
  border: solid 1px rgb(255, 75, 18);
  border-radius: 5%;
}

.burger-button-mobile.rtl{
  margin-right: 0%;
  margin-left: 3%;
}


.navbar-elements{
  justify-content: space-between;
}

.search-button-mobile{
  display: inline-flex;
  align-self: center;
  /* min-width: 50px; */
}

.header-button-row-mobile{
  display: flex;
  justify-content: space-between;
  margin-right: 2%;
  width: 28%;
}

.header-button-row-mobile.rtl{
  flex-direction: row-reverse;
  margin-right: 0%;
  margin-left: 2%;
}

.shopping-cart-button-mobile{
  align-self: center;
  display: inline-flex;
  /* color: orange;
  text-decoration: none; */
}

.shopping-cart-button-mobile a{
  color: rgb(255, 75, 18);
  text-decoration: none;
}

.search-input-mobile-container{ 
  display: block;
  /* position: absolute; */
  /* top: 56px; */
  padding-top: 1%;
  /* margin-left: 2%; */
  /* margin-left: 2%; */
  /* padding: 3%; */
  /* padding */
  /* min-width:350px; */
  /* padding-left: 3%; */
  /* padding-right: 6%; */
  padding-bottom: 3%;
  /* min-width: 92%; */
  width: 100%;
  background-color: black;
}

.search-input-mobile{
  display: block;
  /* position: relative; */
  margin-left: 5%;
  width: 90%;
  /* back */
  /* padding-left: 20%; */
  /* padding-left: ; */
}
}