.footer{
  background-color: black;
  font-family: Helvetica, arial, sans-serif;
  color: rgb(255, 52, 0);
  /* position: sticky;
  top: 0; */
  /* flex: 1; */
  z-index: 1000;
  width: 100%;
}

.footer-content{
  display: flex;
  justify-content: space-between;
  padding-left: 2%;
  padding-right: 2%;
}

.footer-content.rtl {
  flex-direction: row-reverse;
}

.footer-branding-info.rtl{
  flex-direction: row-reverse;
}

.footer-branding-info.rtl .footer-logo-container{
  display: inline-block;
  direction: rtl;
  /* margin-left: -15%; */
  margin-right: 0%;
}

.footer-branding-info.rtl .footer-legal-text{
  /* margin-right: 20%; */
  margin-right: 5%;
}

.footer-legal-info.rtl{
  direction: rtl;
 margin-left: 2%;
}

.footer-legal-info.rtl .footer-legal-text{
  margin-left: 10%;
  margin-right: 0%;
}


.social-media-links{
  color:black;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 15em;
  
}

.social-media-links.rtl{
  flex-direction: row-reverse;
  margin-left: 32em;
  margin-right: 0%;
}

.social-media-links a{
  margin-right: 20%;
  color: black;
}

.footer-branding-info{
  display: flex;
  align-items: center;

}


.footer-logo-container{
  display: inline-block;
  /* margin-left: 0%; */
  margin-right: 10%;
  width: 100%;
  /* -webkit-margin-before: -20%; */
}

.footer-legal-text{
  color: white;
  margin-right: 15%;
  white-space: nowrap;
}

.footer-logo{
  /* width: 40%; */
  max-height: 70px;
}

.circle-icon {
  background: rgb(255, 52, 0);
  padding:10px;
  border-radius: 50%;
  margin-right: 20%;
  cursor: pointer;
}




.footer-legal-info{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 2%;
}

.footer-content-mobile{
  display: none;
}

.footer-legal-info:last-child{
  cursor: pointer;
}



@media only screen and (min-width: 1459px) and (max-width: 1538px){
  .footer{
    font-size: 14px;
  }
}

@media only screen and (min-width: 1366px) and (max-width: 1458px){
  .footer{
    font-size: 12px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px){
  .footer{
    font-size: 11px;
  }
}


@media only screen and (min-width: 0px) and (max-width: 800px){
  .footer-content{
    display: none;
  /* display: inline-flex; */
  /* font-size: 10px; */
  }

  .circle-icon{
    margin-right: 0%;
    padding: 5px;
  }

  .footer-content-mobile{
    display: flex;
    flex-direction: column;
    /* padding-top: 3%; */
    padding-bottom: 2%;
  }
  
  .social-media-links{
    /* margin-right: 25em; */
    /* margin-left: 14%; */
    margin-left:4%;
    margin-top: 2%;
    width: 60%;
    margin-right: 0%;
  }

  .social-media-links.rtl{
    margin-left: 0%;
    /* margin-top */
    margin-right: 4%;
  }

  .social-media-links a{
    margin-right: 0%;
  }

  .footer-branding-info{
    /* flex-direction: column; */
  }

  .footer-legal-info{
    font-size: 10px;
    margin-left: 2%;
    margin-right: 2%;
    margin-top: 2%;
    align-items: flex-end;
  }

  .footer-logo-container{
    display: flex;
    width: 30%;
    margin-left: 0%;
    margin-right: 0%;
  }

  /* .margin */
  .footer-branding-info.rtl .footer-logo-container{
    margin-left: 0%;
  }

  .footer-legal-info.rtl .footer-legal-text{
    width: 40%;
    margin-left: 0%;
    white-space: normal;
  }

  .footer-legal-info.rtl:first-child{
    /* width:%; */
    text-align: left;
  }

  .footer-legal-info.rtl div:last-of-type {
    /* whie */
    /* width: 60%; */
    text-align: left;
  }

  .footer-legal-text{
    margin-right: 0%;
  }





}