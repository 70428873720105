.unit-stepper{
  padding-top: 2%;
  padding-left: 2%;
  padding-right: 2%;
  padding-bottom: 1%;
  background: black;
  flex: 1 1 auto;
  height: 100px;
  overflow-y: auto;
}

.unit-stepper.rtl{
  direction: rtl;
  
  
}


.stepper-line{
  background-color: rgb(255, 75, 18);
  width: 1px;
  height: 3rem;
  margin-left: 8%;
  margin-top: 5px;
  align-self: center;
}

.unit-stepper-item{
  display: flex;
  padding-left: 5%;
  background: rgb(22, 22, 22);
  padding-top: 2%;
  padding-bottom: 2%; 
  margin-bottom: 2%;
}

.unit-stepper-item.rtl{
  /* flex-direction: row-reverse; */
  padding-right: 5%;
  padding-left: 0%;
}

.unit-stepper-item.rtl .unit-stepper-text{
  padding-left: 0%;
  padding-right: 5%;
}

.unit-stepper-item:hover{
  background: #1d1d1d;
}

.unit-stepper-icon{
  background: rgb(255, 75, 18);
  padding:5px;
  padding-bottom: 1px;
  border-radius: 50%;
  margin-top: 1%;
  cursor: pointer;
  height: 100%;
}

.unit-stepper-text{
  padding-left: 5%;
  padding-top: 2.25%;
  font-family: Arial, Helvetica, sans-serif;
  text-transform: none;
  font-size: 14px;
}