.page-header{
  color: white;
  font-family: 'Archivo Black', arial, sans-serif;
  text-transform: uppercase;
  font-size: 30px;
  background: black;
}


.page-header-title-container{

  background: rgb(255, 75, 18);
  margin-left: 0%;
  margin-right: 45%;
  padding-top: 1%;
  padding-bottom: 1%;
  clip-path: polygon(0px 0px, 100% 0%, 92.56% 100%, 0% 100%);
  /* display: block; */


}

.page-header-title-container.rtl{
  /* direction:"rtl"; */
  margin-left:60%;
  margin-right: 0%;
  /* margin-right: 20%; */
  clip-path: polygon(0px 0px, 100% 0%, 100% 100%, 7.44% 100%);
}

.page-header-title{

padding-left: 18%;
white-space: nowrap;
}

.page-header-title.rtl{
  /* padding-left: 10%; */
  text-align: right;
  /* margin-left: 60%; */
  padding-right: 25%;
white-space: nowrap;
}

.page-header-filter{
  /* float: right; */
  /* width: 40%; */
  /* padding-top: %; */
  width: 20%;
  padding-top: 0.65%;
  margin-right: 5%;
}

.page-header-buttons{
  float: right;
  display: flex;
  width: 40%;
}

.page-header-buttons.rtl{
  float: left;
  display: flex;
  flex-direction: row-reverse;
  width: 55%;
}



.filter-button .button{
  width: 100%;
  /* padding-right: 10%; */
  padding-top: 5%;
  padding-bottom: 5%;
  font-weight: 400;
  text-align: center;
}

.filter-button .button:hover{
  box-shadow: none;
  font-weight: 700!important;

  transition: none;
}

.higher-priority{
  z-index: 1001;
}

.filter-section-header{
  
  margin-bottom: 2%;
}

.page-header-sort{
  width: 20%;
  padding-top: 0.65%;
  margin-right: 5%;
}
.sort-ratings-button .button{
  padding-left: 24%;
  padding-bottom: 5%;
  width: 100%;
  padding-top: 5%;
}

.sort-ratings-button .button:hover{
  box-shadow: none;
  font-weight: 700!important;

  transition: none;
}

.filter-checkbox-row{
  display: flex;
}

.filter-submit-button{
  text-align: right;
  margin-top: 5%;
}

.filter-submit-button.rtl{
  text-align: left;
}
.filter-submit-button .button:hover{
  box-shadow: none;
  font-weight: 700!important;

  transition: none;
}


@media only screen and (min-width: 360px) and (max-width: 800px){
  .page-header-title-container{
    margin-right: 30%;
  }

  .page-header-buttons{
    /* display: none; */
  }

.page-header-title-container.rtl{
  /* direction:"rtl"; */
  margin-left:39%;
  margin-right: 0%;

}

.page-header-title{
  white-space: normal;
}

.page-header-title.rtl{
  white-space: normal;
  padding-right:15%;
}

  .page-header{
    font-size: 20px;
    /* white-space: nowrap; */
  }
}


@media only screen and (min-width: 360px) and (max-width: 800px){
.filter-checkbox-row{
  flex-direction: column;
}

.page-header-buttons{
  display: none;
}

.page-header-buttons.rtl{
  display:none;
}
}