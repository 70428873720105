.modal.success-modal{
  z-index: 100;
  position: fixed;
  top: 1vh;
  left: 25%;
  width: 50%;
  background: #007E33;
  border: 2px solid #00C851;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 1%;
  z-index: 10003;
  text-align: center;
}

.modal__header.success-modal__header{
  display: none;

}