.search-page{
  color: white;
  width: 100%;
  margin-bottom: 15%;
}

.no-results{
  box-shadow: 0 0 2px white;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 16%;
  text-align: center;
  padding-top: 5%;
  padding-bottom: 5%;

}
.search-content{
  display: flex;
  /* width: 100%; */
}
.search-content.rtl{
  /* display: flex; */
  flex-direction: row-reverse;
}
.filtered-results{
  width: 75%;
}
.filtered-results .course-list{
  margin-left: 2%;
}
.filter-sidebar-container{
  /* margin-top: 1.7%; */
  /* border-top: 2px solid grey; */
  width: 25%;
  display: flex;
  flex-direction: column;
  
  /* margin-left: 2%; */
  /* background: black; */
}

.search-content.rtl .filter-sidebar-container{
  width: 15%;
  text-align: right;
}

.search-content.rtl .filtered-results{
  width: 80%;
}

.tree-filter-sidebar-header{
 font-family: "Helvetica", "Arial", sans-serif;
 font-size: 18px;
 margin-bottom: 5%;
}
.no-results-text{
  margin-bottom: 2%;
}

.filter-sidebar-header{
  font-family: "Helvetica", "Arial", sans-serif;
  font-size: 18px;
  margin-bottom:2%;
  margin-top: 15%;
}

.filter-sidebar-header.rtl{
  margin-top: 40%;
}

.search-container-header{
  /* margin-left: 18%; */
  font-family: "Helvetica", "Arial", sans-serif;
  margin-bottom: 5%;
  font-size: 30px;
  font-weight: 700;
  
}

.search-container-header.rtl{
  text-align: right;
}

.search-container{
  margin-top: 2%;
  margin-left: 5%;
  margin-right: 5%;
  /* margin-bottom: 20%; */
}

.search-filter-button {
  width: 40%;
  margin-bottom: 2%;
}

.search-filter-button .button{
  padding-left: 8%;
}

.Mui-disabled{
  color: grey;
}

.search-sort-dropdown{
  width: 20%;
  margin-left: 26%;
}

.search-sort-dropdown.rtl{
  margin-left: 57%;
  text-align: right;
}

.ar-text{
  direction: rtl;
}

.search-sort-dropdown .button{
  display: none;
}

.filter-drawer-header{
  background-color: black;
  padding-left: 5%;
  padding-top: 5%;
  padding-bottom: 5%;
  border-top: .4rem rgb(255, 75, 18) solid; 
  color: rgb(255, 75, 18);
  margin-bottom: 5%;
  display: flex;
  align-items: center
  /* vertical-align: initial; */
}

.filter-drawer .filter-sidebar{
  margin-left: 10%;
}

@media only screen and (min-width: 360px) and (max-width: 800px){
.filter-sidebar-container{
  display:none;
}

.search-content.rtl .filtered-results{
  width: 100%;
}

.search-sort-dropdown .button{
  display: inline-block
}
.filtered-results{
  width: 100%;
}

.filtered-results .course-list{
  margin-left: 10%;
  margin-right: 10%;
}

.search-container-header{
  font-size: 20px;
  margin-left: 10%;
}

.search-sort-dropdown.rtl{
  flex-direction: row-reverse;
  margin-left: 0%;
}

.search-sort-dropdown.rtl .button{
  direction: rtl;
  padding-left: 12%;
}
/* 
.search-sort-dropdown{
  display: none;
} */

.search-sort-dropdown{
  display: flex;
  width: auto;
  align-items: center;
  justify-content: flex-start;
  margin-left: 5%;
  margin-right: 5%;
}
.search-sort-dropdown .button{
  /* margin-right: 2%; */
  /* height: 100%; */
  padding-top: 5%;
  margin-right: 2%;
  padding-bottom: 5%;
}

.filter-drawer-title{
  /* padding-bottom: 1%; */
  padding-top: 1%;
  padding-left: 5%;
  font-size: 20px;
  /* align-self: center; */
}

.filter-drawer-close-button{
  /* padding: 2%; */
  /* height: 100%; */
  /* background: rgb(255, 75, 18); */
  padding:5px;
  padding-bottom: 1px;
  border-radius: 50%;
  margin-top: 1%;
  cursor: pointer;
  height: 100%;
  border: 2px solid rgb(255, 75, 18);
  border-radius: 50%;
}


.filter-sidebar{
  /* margin-left: 10%; */
}

.filter-drawer.rtl{
  direction: rtl;
}

.filter-drawer.rtl .filter-sidebar{
  margin-left: 0%;
  margin-right: 20%;
}

.filter-drawer.rtl .filter-drawer-header{
  padding-left: 0%;
  padding-right: 5%;
}

.filter-drawer.rtl .filter-drawer-title{
  padding-left: 0%;
  padding-right: 5%;
}

.filter-sidebar-header.rtl{
  margin-top: 20%;
}
}