.course{
  margin-top: 0%;
}

.video-container{
  overflow: hidden;
  padding-top: 39%;
  padding-left: 0%;
  margin: 0;
  position: relative;
  width: 69%;
  height: 70%;
  z-index: 10;
  background: black;
}

.course-details{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.course-details.rtl{
  flex-direction: row-reverse;
}

.course-outline{
  padding-top: 1.5px;
  width: 31%;
  color: white;
  font-size: 21px;
  text-transform: uppercase;
  background: rgb(22, 22, 22);
  display: flex;
  flex-direction: column;
}

.course-outline-label-container{
  z-index: 0;
  background: rgb(255, 75, 18);
  width: 50%;
  clip-path: polygon(0px 0px, 100% 0%, 92.56% 100%, 0% 100%);
  padding-top: 2%;
  padding-right: 5%;
  padding-bottom: 1%;
}

.course-outline-label-container.rtl{
  clip-path: polygon(0px 0px, 100% 0%, 100% 100%, 7.44% 100%);
  text-align: right;
  margin-left:45%;
}

.course-outline-label{
  /* transform: skewX(20deg); */
  padding: 2%;
  
  padding-left: 20%;
  margin-bottom: 2%;
}


.title-section{
  position:absolute;
  border:solid 1px #000;
  width:1399px;
  height:720px;
  left:0px;
  top:500px;
  background:grey;
  box-shadow:0 0 10px #666;
  
}

.overlay{
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  display: block;
  position: absolute;
  background-color: black;
}

.overlay.rtl{
  direction: rtl;
  /* text-align: right; */
}
.overlay.rtl .course-header{
  left: 0;
  right: 5%;
}

.overlay.rtl .course-header .course-title .course-rating{
  padding-right: 1%;
  padding-left: 0%;
}



.course-header{
  top: 20%;
  left: 5%;
  font-size: 40px;
  position: absolute;
  color: white;
  z-index: 100;
  font-weight: 700;
  width: 100%;
}

.course-instructor{
  font-size: 21px;
  font-weight: 400;
  font-family: Arial, Helvetica, sans-serif;
}

.instructor-name-and-avatar{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-content: center;
  width: 13.2%;
}

.instructor-avatar{
  border-radius: 50%;
  width: 5rem;
  height: 5rem;
  object-fit: cover;
}

.instructor-avatar-container{
  margin-left: auto;
  margin-right: auto;
  display: block;
  padding-bottom: 10%;
}

.instructor-name{
  color: white;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.course-header-description{
  font-size: 18px;
  font-family: Arial, Helvetica, sans-serif;
}
.course-thumbnail{
  
  width: 100%;
  height: 100%;
  opacity: 0.5;
  filter: blur(2px);
}


.video{
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.video-close-icon{
  top: 0;
  right: 2%;
  background: rgb(255, 75, 18);
  padding:5px;
  padding-bottom: 1px;
  border-radius: 50%;
  margin-top: 1%;
  cursor: pointer;
  display: block;
  position: absolute;
  z-index: 11;
  color: white;
}

.video-close-icon.rtl{
  top: 0;
  left: 2%;
  right: unset;
}

.course-description{
  display: flex;
  margin-top: 2%;
  margin-left: 3%;
  margin-right: 3%;
  margin-bottom: 2%;
  text-align: left;
  color: rgb(255, 75, 18);
  font-size: 20px;
  font-family: 'Archivo Black', arial, sans-serif;
}

.course-description.rtl{
  direction: rtl;
  text-align: right;
}

.course-description.rtl .course-description-body{
  margin-left: 0%;
  margin-right: 3%;
}

.course-description-header{
  color: rgb(255, 75, 18);
  text-transform: uppercase;
  font-size: 40px;
  margin-bottom: 1%;
}

.course-stat-header{
  padding-top: 2%;
  color: rgb(255, 75, 18);
  
}

.course-description-content{
  color: white;
  font-size: 14px;
  line-height: 1.6;
  font-family: Arial, Helvetica, sans-serif;
}

.instructor-information{
  display: flex;
  color: white;
  border-top: 5px solid black;
  padding-top: 2%;
  padding-bottom: 2%;
  padding-left: 3%;
 
}

.course-rating{
  padding-left: 1%;
}

.button-row{
  width: 50%;
  display: flex;
  justify-content: flex-start;
  margin-top: 3%;
}


.overlay.rtl .course-header .button-row .button{
  margin-left: 9%;
  margin-right: 0%;
  direction: rtl;
  padding-left: 10%;
  padding-right: 8%;
}

.overlay.rtl .course-header .button-row .button svg{
  margin-left: 10%;
  margin-right: 0%;
}

.instructor-bio{
  display: flex;
  flex-direction: column;
  margin-left: 2.1%;
}

.course-stats{
  margin-top: 3.5%;
  color: white;
  
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 13.2%;
}

.course-stat-item{
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid black;
  padding-top: 5%;
  padding-bottom: 4%;
}

.course-stat-item:first-child{
  padding-top: 2%;
}
.course-stat-item:last-child{
  border-bottom: 0px;
}
.course-description-body{
  margin-left: 3%;
  width: 80%;
}

.button-row .button{
  margin-right: 10%;
}

.my-node-enter {
  opacity: 0;
}
.my-node-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}
.my-node-exit {
  opacity: 1;
}
.my-node-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}



.course-divider{
  border-top: 5px solid black;
}

.course-list-header{
  margin-top: 5%;
  color:white;
  font-size: 21px;
  text-transform: uppercase;
  padding-bottom: 1%;
  margin-bottom: 1%;
  border-bottom: 2px solid black;
}
.instructor-courses{
  width: 70%;
}
.instructor-course-list{
  width: 50%;
  margin-bottom: 3%;
}
.instructor-course-list-item{
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  border-bottom: 1px solid black;
  padding-top: 3%;
  padding-bottom: 2%;
  cursor: pointer;
}

.instructor-course-list-item:hover{
  color: rgb(255, 75, 18);
}



.instructor-course-list-item:last-child{
  border-bottom: 0px;
}

.course-owned-transparent{
  font-size: 14px;
  margin-left: 2%;
  background-color: transparent;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 8px;
  padding-right: 8px;

  vertical-align: center;
  border: 1px solid white;
  white-space: nowrap;
}

.overlay.rtl .course-header .course-title .course-owned-transparent{
  margin-left: 0%;
  margin-right: 2%;
  /* direction: ltr; */
}

.course-stats-header{
 display: none;
}

.course-divider-mobile-only{
display: none;
}


@media only screen and (min-width: 0px) and (max-width: 800px){

  .course-stats-header{
    display: block
  }


  .course-divider-mobile-only{
    border-top: 5px solid black;
    margin-left: -3%;
    margin-right: -3%;
    display: block;
  }
  .course-details{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .course-details.rtl{
    flex-direction: column;
  }
  .video-container{
    width: 100%;
    padding-top: 100%;
    padding-bottom: 10%;
  }
  .course-outline{
    width: 100%;
  }
  .course-description{
    flex-direction: column;
    text-align: center;
    /* margin: auto; */
    align-content: center;
    /* margin-left: 0%; */
  }

  .course-description.rtl{
    direction: rtl;
    text-align: center;
  }

  
  
  .instructor-courses{
    width: 100%;
  }
  .course-list-header{
    text-align: center;
  }
  .more-courses-button .button{
    white-space: pre-wrap;
  }
  .more-courses-button{
    width: 100%;
  }

  .instructor-name-and-avatar{
    width: 100%;
  }
  .button-row{
    flex-direction: column;
    width: 100%;
    margin-top: 5%;
  }

  .button-row .button{

    margin-bottom: 10%;
  }

  .course-stats{
    width: 100%;
  }

  .course-rating{
    display: inherit;
  }

  .course-title{
    font-size: 32px;
    padding-right: 5%;
  }
  
  .overlay.rtl .course-header .course-title{
    padding-right: 0%;
  }


  
  .course-description-body{
    width: 100%;
    margin-left: 0%;
  }

  .course-description.rtl .course-description-body{
    margin-right: 0%;
  }
  

  .course-description-header{
    font-size: 32px;
  }

 

  .unit-stepper{
    height: 200px;
  }

  .instructor-name{
    margin-bottom: 10%;
  }

  .instructor-course-list{
    width: 100%;
  }
}