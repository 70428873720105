.language-switch-mobile{
  display: none;
}
@media only screen and (min-width: 0px) and (max-width: 800px){
.language-switch-mobile{
  display: inline-flex;
  border: solid 1px rgb(255, 75, 18);
  border-radius: 5%;
  align-self: center;
  font-size: 14px;
  padding: 3%;
  margin-right: 2;
}
}