.modal.error-modal{
  z-index: 100;
  position: fixed;
  top: 1vh;
  left: 25%;
  width: 50%;
  background: #b00020;
  border: 2px solid #CF6679;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 1%;
  z-index: 10003;
  text-align: center;
}

.modal__header.error-modal__header{
  display: none;

}