.categories{
  margin-top: 2%;
  margin-left: 10%;
  margin-right: 10%;
  color: white;
}

.category-item{
  width: 30%;
  margin-bottom: 5%;
 
}

.category-item.rtl{
  text-align: right;

}


.subcategories{
  background: black;
  font-family: Arial, Helvetica, sans-serif;
  position: relative;
  font-weight: 400;
  min-height: 220px;
  border-bottom-left-radius: 2%;
  border-bottom-right-radius: 2%;
}

.subcategories ul{
  margin: 0;
  margin-left: 4%;
  font-size: 17px;
  list-style: none;
  padding: 0;
  padding-top: 5%;
}

.category-item.rtl .subcategories ul{
  margin-left: 0%;
  margin-right: 4%;
}

.see-more-category{
  position: absolute;
  bottom: 10%;
  left: 4%;

}

.category-item.rtl .see-more-category{
  left: 0%;
  right: 4%;
}
.see-more-category a{
  color: rgb(255, 75, 18);
  text-decoration: none;
  padding-bottom: 2%;
  border-bottom: 1px solid rgb(255, 75, 18);

}

.category-item a{
  text-decoration: none;
}

.category-item:hover{
    -webkit-box-shadow: -1px 4px 22px 5px rgba(255,52,0,0.75);
-moz-box-shadow: -1px 4px 22px 5px rgba(255,52,0,0.75);
box-shadow: -1px 4px 22px 5px rgba(255,52,0,0.75);
transition: 0.1s all;
}

.see-more-category a:hover{
  color: rgb(255, 75, 18);
  font-weight: 700;
}
.subcategories ul li{
  margin-bottom: 2%;
}

.subcategories ul li a{
  text-decoration: none;
  color: white;
}

.subcategories ul li a:hover{
  color: rgb(255, 75, 18);
  transition: all 0.3s;
}

.parent-category-name{

font-size: 26px;
font-weight: 900;
line-height: 1;
color: white;
}

.title-box{
  padding: 5px 30px;
  min-height: 96px;
  display: flex;
  align-items: center;
  background: rgb(255, 75, 18);
  border-top-left-radius: 2%;
  border-top-right-radius: 2%;
}

.title-box.rtl{
  justify-content: flex-end;
}
.parent-categories{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.page-header-buttons-mobile{
  display: none;
}
@media only screen and (min-width: 0px) and (max-width: 800px){
  .category-item{
    width: 100%;
    margin-bottom: 20%;
   
  }

  .categories{
    margin-top: 5%;
  }

  .parent-categories{
    flex-direction: column;
  }


.parent-category-name{

  font-size: 22px;
  font-weight: 900;
  line-height: 1;
  color: white;
  }

.page-header-buttons-mobile{
  display: flex;
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 5%;
  margin-bottom: 5%;
}
  
}