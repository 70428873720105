.MuiCircularProgress-colorPrimary{
  color: rgb(255, 75, 18) !important;
}

.home-slider-loading-spinner{
  padding-top: 5%;
  padding-bottom: 15%;
  position: relative;
  text-align: center;
}

.courses-loading-spinner{
  position: relative;
  text-align: center;
  padding-bottom: 50%;
}

.unit-stepper-loading-spinner{
  position: relative;
  text-align: center;
  padding-top: 2%;
}

.payment-loading-spinner{
  position: relative;
  text-align: center;
}

@media only screen and (min-width: 360px) and (max-width: 414px){
  .courses-loading-spinner{
    /* padding-bottom: 100%; */
    margin-bottom: 100%;
  }
}