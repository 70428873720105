.terms-and-conditions{
 color: white;
 
}

.terms-and-conditions-main-content{
   /* margin-top: 2%; */
   margin-left: 10%;
   margin-right: 10%;
}

.terms-and-conditions p{
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
}

.terms-and-conditions ol{
  font-family: Arial, Helvetica, sans-serif;
  
}

.terms-and-conditions a{
  color: rgb(255, 75, 18);
}