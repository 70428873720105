.authentication{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.sign-up__modal-content{
  margin: 2%;
}

.sign-up__modal-actions{
  text-align: center;
}

.name-inputs{
  display: flex;
  justify-content: space-between;
  margin-bottom: 1%;
}

.first-name{
  width: 45%;
}

.last-name{
  width: 45%;
}

.authentication-footer{
  text-align: center;
}

.terms-and-conditions-checkbox{
  margin-bottom: 5%;
}

.sign-up__modal-header-rtl .modal__header-title-container{
  background: rgb(255, 75, 18);
  margin-right: 0%;
  margin-left: 65%;
  padding-top: 1%;
  padding-bottom: 1%;
  clip-path: polygon(0px 0px, 100% 0%, 100% 100%, 7.44% 100%);
  text-align: right;
  padding-right: 2.5%;
}

.country{
  position: absolute;
  width: 42%;
}

.country-field-container{
  width: 40%;
  height: 80px;
}

.country-and-date-container{
  display: flex;
}

.date-container{
 width: 52%;
 margin-left: 20%;
}

.date-container.rtl{
  margin-left: 0%;
  margin-right: 20%;
}
.gender{
  position: absolute;
  width: 42%;
}

.gender-container{
  width: 45%;
  height: 80px;
}

.email-and-gender-container{
  display: flex;
  justify-content: space-between;
}

.email{
  width: 45%;
}

.forgot-password{
  color: rgb(255, 75, 18);
  cursor: pointer;
  margin-bottom: 5%;
  /* margin-left: 2%; */
}

.forgot-password:hover{
  text-decoration: underline;
}

.terms-checkbox-label a{
  color: rgb(255, 75, 18);
  text-decoration: none;
}

.terms-checkbox-label a:hover{
  /* color: rgb(255, 75, 18); */
  text-decoration: underline;
}
@media only screen and (min-width: 0px) and (max-width: 414px){
.date-container{
  width: 45%;
  margin-left: 55%;
}

.gender-container{
  height: 67px;
}

.gender{
  width: 41%;
}

.country{
  width: 41%;
}
.email.rtl label{
  font-size: 14px;
}

.sign-up__modal-header-rtl .modal__header-title-container{
  margin-left: 30%;
}

.modal__header-title-container{
  margin-right: 32%
}
.terms-checkbox-label{
  font-size: 13px;
}

}




