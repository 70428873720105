.payment-summary{
  margin-top: 2%;
  margin-left: 35%;
  margin-right: 35%;
  box-shadow: 0 0 3px white;
  margin-bottom: 14%;
  padding: 1%;
  
}

.payment-summary.rtl{
  direction: rtl;
}

.payment{
  color: white;
  font-family: Arial, Helvetica, sans-serif;;
}


.table-heading{
  font-size: 24px;
  width: 100%;
  font-weight: 700;
  margin-bottom: 2%;
}

.payment-details{
  padding-bottom: 8px;
margin-bottom: 8px;
border-bottom: 1px solid #cacbcc;
border-collapse: separate;
width:100%;
}

.row-label{
  line-height: 24px;
vertical-align: middle;
width: 100%;
}

.row-data{
  text-align: end;
}

.strong-row{
  font-weight: 700;
  font-size: 18px;
}

.table-total{
  margin-top: 2%;
  margin-bottom: 5%;
}

.finalize-payment-button .button{
  width: 100%;
  margin-bottom: 2%;
}


@media only screen and (min-width: 360px) and (max-width: 800px){
  .payment-summary{
    margin-top: 14%;
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 80%;
    padding: 5%;
  }
}