.course-list{
  margin-top: 2%;
  margin-left: 10%;
  margin-right: 10%;
  color: white;
}


@media only screen and (min-width: 0px) and (max-width: 800px){
.course-list{
  margin-bottom: 100%;
}
}