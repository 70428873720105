.coupon-selector{
  display: flex;
  /* justify-content: space-between; */
  
  margin-top: 5%;
}
.coupon-selector-submit-button{
  width: auto;
}

.coupon-selector-field{
  width: 74.8%;
}
.coupon-selector-submit-button .button{
  /* padding-top: 37%; */
  /* padding-bottom: 37%; */
  /* width: 100%; */
  /* height: 83%; */
  height: 100%;
  /* padding-left: 5%; */
  border-left: none;
  padding-right: 22%;
  padding-left: 22%;
}


.coupon-selector.rtl .coupon-selector-submit-button .button{
  border-left: 1px solid rgb(255, 75, 18);
  border-right: none;
  height: 100%;
  /* height: 83%; */
}

.coupon-selector.rtl .coupon-selector-field{
  width: 82.7%;
}

.coupon-selector-error{
  color: rgb(255, 75, 18);
}

@media only screen and (min-width: 360px) and (max-width: 374px){
  .coupon-selector-field{
    width: 76.5%;
  }
}

@media only screen and (min-width: 375px) and (max-width: 389px){
  .coupon-selector-field{
    width: 77.5%;
  }

  .coupon-selector.rtl .coupon-selector-field{
    width: 84.4%;
  }
}

@media only screen and (min-width: 390px) and (max-width: 414px){
  .coupon-selector-field{
    width: 79.5%;
  }

  .coupon-selector.rtl .coupon-selector-field{
    width: 86.4%;
  }
}


@media only screen and (min-width: 768px) and (max-width: 800px){
  .coupon-selector-field{
    width: 87.7%;
  }

  .coupon-selector.rtl .coupon-selector-field{
    width: 91.5%;
  }
}

@media only screen and (min-width: 1326px) and (max-width: 1367px){
  .coupon-selector-field{
    width: 69%;
  }

  .coupon-selector.rtl .coupon-selector-field{
    width: 79%;
  }
}

@media only screen and (min-width: 1326px) and (max-width: 1367px){
  .coupon-selector-field{
    width: 69%;
  }

  .coupon-selector.rtl .coupon-selector-field{
    width: 79%;
  }
}


@media only screen and (min-width: 1200px) and (max-width: 1280px){
  .coupon-selector-field{
    width: 67%;
  }

  .coupon-selector.rtl .coupon-selector-field{
    width: 77.5%;
  }
}

@media only screen and (min-width: 1900px) and (max-width: 1920px){
  .coupon-selector-field{
    width: 77.5%;
  }

  .coupon-selector.rtl .coupon-selector-field{
    width: 84.7%;
  }
}

