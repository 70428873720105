.home{
  display: flex;
  /* min-height: 100vh; */
  flex-direction: column;
  flex: 1 0 auto;
  align-items: center;
 width: 100%;
}

.main-content{
  width: 100%;
}

.greeting-backdrop{
  box-sizing: border-box;
  display: flex;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("https://jooinn.com/images/person-using-laptop-computer-during-daytime.jpg");
  height: 500px;
  width: 100%;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size:cover;
}

.greeting-headline{
  font-family: Helvetica,Arial,sans-serif;
  color: white;
font-size: 50px;
font-weight: 700;
line-height: 1.33;
}

.greeting-content{
  width: 100%;
  padding-left: 10vw;

  margin: 10% auto;
  box-sizing: border-box;
}

.greeting-search-field{
  max-width: 30%;
  z-index: 1;
}
.greeting-content.rtl{
  text-align:right;
  direction: "rtl";
  padding-left: 60vw;
  padding-right: 10vw;
}

.greeting-content.rtl .greeting-search-field{
  max-width: none;
}

.what-we-do-container{
  display: block;
  border: none;

  background-color: #ff4b12;
  /* background-image: linear-gradient(315deg, #FF3400 0%, #b82e1f 74%); */



}

.what-we-do{
  color: white;
  font-size: 18px;
  display: flex;
  margin-bottom: 24px;
  margin-top: 0;
  justify-content: space-between;

  padding-left: 9vw;
  padding-right: 9vw;
}

.what-we-do-container.rtl *{
  flex-direction: row-reverse;
  direction: "rtl";
  
}

.what-we-do-col{
  padding: 20px 25px;
  padding-left: 25px;
width: 33%;
box-sizing: border-box;
align-items: center;
}



.what-we-do-container.rtl .what-we-do .what-we-do-col{
  font-size: 21px;
}


.col-lt{
  display: flex;
  text-align: center;
}

.col-cn{
display: flex;
align-items: center;
justify-content: center;

}

.col-rt{
  display: flex;
justify-content: flex-end;
text-align: center;
padding-right: 30px;
}
.what-we-do-col-icon{
  margin-right: 16px;
  margin-left: 16px;
margin-top: -2px;
font-size: 47px;
height: 1em;
}

.browse-container{
max-width: 1405px;
width: 100%;
margin:0 auto;
background-color: rgb(22, 22, 22);
}

.courses-container{
  display: flex;
 
}
.courses-container.rtl{
  /* flex-direction: row-reverse; */
  /* direction: ; */
  direction: rtl;
}

.courses-container.rtl .section-description{
  margin-left: 5%;
}

.courses-container.rtl .section-description .section-description-content{
  text-align: right;
}
.section-description{
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  justify-content: center;
  margin: 15px 15px 28px 0;
  padding: 0 32px;
  max-width: calc(216px * 2 + 40px);
  color: white;
  border: 2px solid grey;
  border-radius: 2%;
  box-sizing: border-box;
}

.section-description-content{
  padding-top: 30%;
  padding-bottom: 30%;
  display: block;
  font-family: Helvetica,Arial,sans-serif;

  
}

.section-description-title{
  font-weight: 700;
  font-size: 21px;
  margin-bottom: 2%;
}

.course-slider{
 margin-left: 5%;
}



@media only screen and (min-width: 1366px) and (max-width: 1450px){
  .section-description{

    max-width: calc(160px * 2 + 45px);
    margin: 15px 15px 28px 42px;
  }


}

@media only screen and (min-width: 1200px) and (max-width: 1365px){
  .section-description{

    max-width: calc(125px * 2 + 45px);
    margin: 15px 15px 28px 40px;
  }
}

@media only screen and (min-width: 0px) and (max-width: 800px){
.greeting-content.rtl{
  padding-left: 0%;
}

.greeting-search-field{
  /* max-width: 60%; */
  max-width: 80%;
}

.greeting-content.rtl .greeting-search-field{
  margin-left: 20%;
}
.browse-container{
  /* display: none; */
  max-width: 800;
}

.section-description{
  margin: 15px 15px 28px 15px;
}
.courses-container{
  flex-direction: column;
}

.greeting-backdrop{
  height: 350px;
}

.what-we-do{
  padding-left: 0%;
  padding-right: 0%;
  text-align: center;
  font-size: 10px;
}

.what-we-do-col{
  flex-direction: column;
}

.what-we-do-col-icon{
  /* height: 1em; */
  margin-top: 0%;
  margin-bottom: auto;
}



.what-we-do-container.rtl .what-we-do .what-we-do-col{
  flex-direction: column;
}

.what-we-do-container.rtl .what-we-do .what-we-do-col{
  font-size: 12px;
}

.what-we-do-container.rtl .what-we-do .what-we-do-col.col-lt .what-we-do-col-icon{
  margin-bottom: 12%;
}

.course-slider{
  margin-left: 4%;
  margin-right: 4%;
}
}

