.banner{
  height: 85px;
  background: rgb(255, 75, 18);
  color: white;
  text-align: center;
  width: 100%;
  /* line-height: 1.1em; */
}
.__hidden{
  display: none;
}
.banner-container{
  display:flex;
}
.banner-text{
  padding: 10px;
  display: flex;
  justify-content: center;
  flex-basis: 100%;
}

.banner-title{
  font-weight: bold;
  /* text-align: right; */
}
.banner-details{
  /* text-align: right; */
}

.banner-timer{
  font-size: 21px;
  font-weight: bold;
}
.banner-timer div{
  padding-bottom: 1%;
}

.banner-container .close-icon-container{
  padding-top: 20px;
  padding-right: 10px;
}

.close-icon{
  cursor: pointer;
}

@media only screen and (min-width: 360px) and (max-width: 414px){
  .banner-text{
    /* font-size: ; */
    font-size: 14px;
  }

  .banner-timer{
    font-size: 15px;
  }

  .banner{
    line-height: 1.1em;
  }
}