.cart-main-content{
  margin-top:3%;
  margin-left: 14%;
  margin-right: 14%;
  color: white;
  display: flex;
  align-items: stretch;
  margin-bottom: 10%;
}

.cart-main-content.rtl{
  direction: rtl;
  text-align: right;
}

.course-counter{
  margin-bottom: 2%;
}

.course-counter.rtl{
  text-align:right;
}

.empty-cart{
  box-shadow: 0 0 2px white;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10%;
  text-align: center;
  padding-top: 5%;
  padding-bottom: 5%;
}


.empty-cart-text{
  margin-top: 1%;
  margin-bottom: 2%;
  font-family: Arial, Helvetica, sans-serif;
}

.cart-details{
  /* width: 70%; */
  width: 100%;
  display: flex;
  flex-direction: column;
  font-family: Arial, Helvetica, sans-serif;
}

.checkout{
  display: flex;
  flex-direction: column;
  width: 30%;
  margin-left: 2%;
  border-left: 2px solid grey;
  padding-left: 2%;
}

.cart-main-content.rtl .checkout{
  border-left: none;
  border-right: 2px solid grey;
  margin-left: 0%;
  margin-right: 2%;
  padding-left: 0%;
  padding-right: 2%;
}

.subtotal{
  font-family: Arial, Helvetica, sans-serif;
  font-size: 20px;
  margin-bottom: 2%;
}

.subtotal-price{
  font-size: 45px;
  margin-bottom: 5%;
  font-weight: 700;
}

.original-price-and-discount{
  margin-bottom: 10%;
  color: grey;
}

.original-price{
  text-decoration: line-through;
}

.checkout-button .button{
 padding-top: 5%;
 padding-bottom: 5%;
 padding-left: 15%;
 padding-right: 15%;
 width: 100%;
}

.auth-choices{
  display: flex;
  flex-direction: column;
  text-align: center;
}

.auth-choice-buttons{
  margin-top: 5%;
  display: flex;
  flex-direction: column;
  margin-right: 10%;
  margin-left: 10%;
 
}

.auth-choice-buttons .button{
  margin-bottom: 10%;
}

.warning-message{
  display: flex;
  justify-content: space-between;
  box-shadow: 0 0 2px rgb(255, 75, 18);
  text-align: center;
  color: rgb(255, 75, 18);
  margin-bottom: 2%;
}

.warning-icon{
 margin-left: 2%;
}

.warning-content{
 display: flex;
 margin-bottom: 1%;
 margin-top: 1%;

}

.warning-text{
  margin-top: 1.5%;
  margin-left: 2%;
  white-space: nowrap;
  font-size: 15px;
}

.warning-close-button{
  cursor: pointer;
}

.current-coupon-code{
  font-weight: 700;
  font-size: 17px;
  color: white;
}

.checkout-coupon-code{
  margin-top: 5%;
}



@media only screen and (min-width: 360px) and (max-width: 800px){
  .empty-cart{
    padding: 10%;
    margin-bottom: 50%;
  }

  .course-counter{
    margin-bottom: 5%;
  }

  .cart-main-content{
    flex-direction: column;
  }
  .checkout{
    width: 100%;
    border-left: none;
    margin-left: 0%;
    padding-left: 0%;
  }

  .warning-text{
    font-size: 12px;
    white-space: normal;
  }

  .warning-message{
    margin-bottom: 5%;
  }

  .cart-main-content.rtl .checkout{
    width: 100%;
    border-right: none;
    margin: 0%;
    padding: 0%;
  }
}