.MuiAutocomplete-root{
  display: inline;
}


.search-input-form{
  
  width: 100%;
}

.MuiAutocomplete-hasClearIcon{
  padding-right: 0px!important;
}