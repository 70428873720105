.filter-buttons{
  display: none;
}


@media only screen and (min-width: 0px) and (max-width: 800px){
  .filter-buttons{
    display: flex;
    margin-right: 10%;
    margin-left: 10%;
    margin-top: 5%;
    margin-bottom: 5%;
    justify-content: space-between;
  }

  .without-filter-button{
    justify-content: normal;
  }

  .filter-buttons.without-filter-button .page-header-sort{
    margin-right: 10%;
  }

  .filter-buttons.rtl.without-filter-button .page-header-sort{
    margin-right: 0%;
    margin-left: 10%;
  }
  

  .filter-buttons.rtl{
    direction: rtl;
  }
  .filter-buttons.rtl .button{
    direction: rtl;
  }
  
  .filter-buttons button{
    font-size: 12px;
  }

  
  .filter-buttons .filter-button{
    width: 33%;
  }

  .filter-buttons.rtl .filter-button .button{
      padding-left: 8%;
      padding-right:0%;
  }

  .filter-buttons.rtl .filter-button .button svg{
    margin-right: 0%;
    margin-left: 10%
  }
        
  
  
  .filter-buttons .page-header-sort{
    width: 30%;
    padding-top: 0%;
    margin-right: 0%;
  }
  
  .filter-buttons .sort-ratings-button .button{
    padding-left: 19%;
  }

  .filter-buttons.rtl .sort-ratings-button .button{
    padding-left: 0%;
  }
}
