.cart-table{
  box-shadow: 0 0 2px white;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 10%;
  text-align: center;
 width:100%;
font-family: Arial, Helvetica, sans-serif;
font-size: 14px;
}