.card-slider {
  
  
  padding: 40px;
  background: rgb(22,22,22);
  width: 50vw;
}

.slick-slide img {
  margin: auto;
}

.card-slider.rtl{
  width: 45vw;
}




@media only screen and (min-width: 1366px) and (max-width: 1599px){
  .slick-slide > div{
    margin-right: 6%;
  }
  .card-slider {
    width: 55vw;
  }
  
  .card-slider.rtl{
    width: 55vw
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1365px){
  .slick-slide > div{
    margin-right: 10%;
  }
  .card-slider {
    width: 60vw;
  }
  .card-slider.rtl{
    width: 60vw
  }
}


@media only screen and (min-width: 0px) and (max-width: 800px){
  .card-slider{
    width: 70vw;
  }

  .card-slider.rtl{
    width: 70vw;
  }

  .slick-slide > div{
    margin-right: 5%;
    margin-left: 5%;
  }


}