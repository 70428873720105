.course-item{
  display: flex;

padding: 1%;
border-top: solid 2px gray;
font-family: Arial, Helvetica, sans-serif;
padding-bottom: 2%;
}

.course-item.rtl{
  flex-direction: row-reverse;
}

.course-item-text-content{
  margin-left: 2%;
  width: 90%;
  margin-right: 2%;
  text-align: left;
  box-sizing: border-box;
}

.course-item-text-content.rtl{
  text-align: right;
  direction: rtl;
}
.course-item-title{
margin-bottom: 0.5%;
font-weight: 700;
font-size: 21px;
}

.course-item-text-content.rtl .course-owned{
  text-align: right;
  margin-left: 0%;
  margin-right: 2%;
}

.course-item-instructor{
  margin-bottom: 1%;
  font-size: 14px;
}
.course-item-description{
  font-size: 12px;
  color: grey;
  line-height: 1.5;
}

.course-item-price-and-rating{
  text-align: right;
}
.course-item-price-and-rating.rtl{
  text-align: left;
}
.course-item-price{
  font-weight: 700;
  font-size: 21px;
  
}

.course-item-rating{
  display: flex;
  margin-top: 20%;
 
}

.course-item-rating.rtl{
  flex-direction: row-reverse;
}

.rating-label{
  padding-top: 2%;
  margin-left: 5%;
  font-size: 14px;
}

.rating-label.rtl{
  margin-left: 0%;
  margin-right: 5%;
}

.students-enrolled{
  margin-top: 10%;
  font-size: 12px;
  white-space: nowrap;
}

.course-item-link{
  text-decoration: none;
  color: white;
  cursor: pointer;
}

.course-item-link:hover .course-item{
  background: black;
}

.course-owned{

    font-size: 14px;
    margin-left: 2%;
    background-color: rgb(254, 52, 0);
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 5px;
    vertical-align: center;
}


.course-item-button .button{
  font-size: 9px;
  margin-top: 20%;
  padding-top: 5%;
  padding-bottom: 5%;
  padding-right: 5%;
  padding-left: 5%;
 margin-right: 1%;
}

.course-item-button.rtl .button{
  font-size: 13px;
}

.course-item-price-and-rating-mobile{
  display: none;
}

.course-owned-mobile{
  display: none;
}


@media only screen and (min-width: 0px) and (max-width: 767px){
  .course-item-image-container img{
    max-width: 100%;
    height: auto;
    display: block; /* remove extra space below image */
  }

  .course-item-image-container{
    width: 40%;
  }

  .course-item{
    padding-top: 3%;
    padding-bottom: 3%;
  }

  .course-item-description{
    display: none;
  }

  /* .cou */

  .course-item-title{
    font-size: 16px;
  } 

  .course-item-text-content{
    /* width: 100%; */
  }

  .course-item-price-and-rating{
  display: none;
  }

  .course-item-price-and-rating-mobile{
    display: block;
    margin-top: 5%;
  }

  .course-item-rating{
    margin-top: 0%;
  }

  .course-owned{
    display: none;
  }

  .course-owned-mobile{
    display: block;
    margin-top: 2%;
  }

  .course-item-price{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 2%;
  }

  .course-item-button .button{
    font-size: 10px;
    /* padding-top */
    margin-top: 0%;
    vertical-align: middle;
  }

  .course-item-price{
    font-size: 18px;
  }

  .course-owned-mobile span{
    font-size: 12px;
    /* margin-left: 2%; */
    background-color: rgb(254, 52, 0);
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 5px;
    vertical-align: center;
    white-space: nowrap;
  }

  .course-item-text-content{
    /* margin-bottom: 2%; */
  }

  .course-item-instructor{
    margin-top: 2%;
  }

  .course-item-rating.rtl{
    flex-direction: row;
  }

  .course-item-button.rtl .button{
    font-size: 11px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 800px){
  .course-item-description{
    display: none;
  }
}