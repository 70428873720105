.button{
  border: 0;
  text-align: center; 
  padding: 2%;
  cursor: pointer;
  font-size: 16px;
  padding-left: 12%;
  padding-right: 12%;
  white-space: nowrap;

  text-transform: uppercase;
}

.with-icon{
  padding-left: 5%;
}

.button:hover{
  -webkit-box-shadow: -1px 4px 22px 12px rgba(0,0,0,0.75);
-moz-box-shadow: -1px 4px 22px 12px rgba(0,0,0,0.75);
box-shadow: -1px 4px 22px 12px rgba(0,0,0,0.75);
transition: 0.8s all;
}
.button svg{
  margin-right: 10%;
  vertical-align: middle;
}

.disabled{
  background: grey !important;
}

.orange{
  background: rgb(255, 75, 18);
  color: white;
}

.transparent{
  color: white;
  background-color: transparent;
  border: 1px solid white;
}

.orange-transparent{
  background-color: transparent;
  color: rgb(255, 75, 18);
  border: 1px solid rgb(255, 75, 18);
}

.fullwidth{
  width: 100%;
}

