.cart-table-item{
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid gray;
  width: 98%;
  padding-top:1%;
  padding-bottom: 1%;
  padding-left: 1%;
  padding-right:1%;
  align-content: flex-start;
}

.cart-table-item:last-child{
  border-bottom: 0px;
}

.cart-table-item-text-content{
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 80%;
  padding-left: 2%;
}

.cart-table-item-text-content.rtl{
  text-align: right;
  padding-left: 0%;
  padding-right: 2%;
}

.cart-table-item-price-and-interactables{
  display: flex;
  flex-direction: column;
  text-align: right;
}

.cart-table-item-price-and-interactables.rtl{
  text-align: left;
}

.cart-table-item-price{
  margin-bottom: 20%;
  font-weight: 700;
  font-size: 16px;
}
.cart-table-item-price-with-discount{
  margin-bottom: 20%;
  font-weight: 700;
  font-size: 14px;
}
.cart-table-item-original-price{
text-decoration: line-through;
color: grey;
font-size: 16px;
}

.cart-table-item-interactables{
  color: rgb(255, 75, 18);

}

.cart-table-item-interactables:hover{
  font-weight: 700;
  color: rgb(255, 75, 18);
  cursor: pointer;

}


@media only screen and (min-width: 0px) and (max-width: 414px){
.cart-table-item-image-container{
  width: 30%;
}

.cart-table-item-image-container img{
  max-width: 90%;
  height: auto;
  display: block; /* remove extra space below image */
}

.cart-main-content{
  margin-left: 10%;
  margin-right: 10%;
}
}


